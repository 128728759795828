.trackers {
	bottom: $base1*22;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	// left: 0;
	min-height: 100vh;
	pointer-events: none;
	position: fixed;
	right: 0;
	z-index: 20;
	transition: transform 0.3s;
	transform: translateX(0%);

	@include media($mediumBreakpoint) {
		bottom: $base1*16;
	}

	@include media('>medium'){
		bottom: $base1*12;
	}

	@include media('>large') {
		bottom: $base10;
	}

	&.js-fixed {
		position: fixed;
	}

	&.js-beforeActive {
		position: relative;
	}

	&.js-hidden {
		transform: translateX(100%);
		display: none;
	}

	&::before {
		@include ease(opacity, visibility, $duration4);
		@include fadeOut();
		background-color: $colorBgModalCover;
		bottom: 0;
		content: '';
		left: 0;
		pointer-events: auto;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 0;
	}

	&.js-modal::before,
	&.js-beforeActive::before {
		@include fadeIn();
	}

	&__actions {
		margin-top: $base1;
		flex-wrap: nowrap;
		display: flex;
		justify-content: space-around;
		gap: $base2;
		// @include staticColumns($base);

		// @include media($columnsBreakpoint) {
		// 	margin-left: staticColumnPush(1, 12, $gutter);
		// 	width: staticColumnSize(4, 12, $gutter);
		// }
	}

	&__container {
		@include ease(height, $duration4);
		background-image: linear-gradient(to right, $colorBrightFocus , $colorFocus);
		color: $colorInverted;
		flex-grow: 0;
		flex-shrink: 0;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		overflow: hidden;
		padding: $base2 0;
		pointer-events: auto;
		position: relative;
		max-width: $base3*10;
		border-radius: $base2 0 0 $base2;
	}

	&__details {
		@include app-container();
		@include ease(opacity visibility, $duration2);
		left: 0;
		// padding: $base 0 0 $base;
		padding-top: $base3;
		position: relative;
		right: 0;
		top: 0;

		&.js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}

	&__detailsActions {
		@include media($columnsBreakpoint) {
			margin-left: staticColumnPush(8, 12, $gutter);
			width: staticColumnSize(4, 12, $gutter);
		}
	}

	&__group {
		@include ease(height, $duration4);
		overflow: hidden;
		position: relative;

		& + & {
			margin-top: $base4;
		}
	}

	&__groupActions {
		@include media($columnsBreakpoint) {
			margin-left: staticColumnPush(1, 12, $gutter);
			width: staticColumnSize(4, 12, $gutter);
		}
	}

	&__groupDetails {
		@include ease(opacity visibility, $duration2);
		bottom: 0;
		left: 0;
		position: relative;
		right: 0;

		&.js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}

	&__groupInfo {
		@include media($columnsBreakpoint) {
			width: staticColumnSize(7, 12, $gutter);
		}
	}

	&__groupSummary {
		@include media($columnsBreakpoint) {
			@include staticColumns();
			align-items: center;
		}
	}

	&__info {
		@include font(baseSmaller);

		// @include media($columnsBreakpoint) {
		// 	width: staticColumnSize(7, 12, $gutter);
		// }
	}

	&__summary {
		@include app-container();
		// @include media($columnsBreakpoint) {
		// 	@include staticColumns();
		// 	align-items: center;
		// }
		flex-direction: column;
	}

	&__tracker {
		@include media($columnsBreakpoint) {
			@include staticColumns();
		}

		& + & {
			border-top: 1px solid currentColor;
			margin-top: $base;
			padding-top: $base;
		}
	}

	&__trackers {
		padding-top: $base2;
	}

	&__trackerActions {
		@include media($columnsBreakpoint) {
			margin-left: staticColumnPush(1, 12, $gutter);
			width: staticColumnSize(4, 12, $gutter);
		}
	}

	&__trackerInfo {
		@include media($columnsBreakpoint) {
			width: staticColumnSize(7, 12, $gutter);
		}
	}
}
