.searchForm {
	.header & {
		align-items: stretch;
		display: flex;
		flex-grow: 1;
	}

	.block--colorSchemeInverted & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&__content {
		display: flex;
		flex-grow: 1;
	}

	&__input {
		@include font(button);
		border-color: $colorBorder;
		border-style: solid;
		border-width: $buttonBorderSize;
		color: inherit;
		height: 100%;
		padding: ($base * 1.3) $base2;
		width: 100%;

		&:focus {
			border-color: $colorFocus;
			outline: 0;
		}

		.header & {
			@include font(header);
			background-color: transparent;
			border: 0;
			padding-left: 0;
			padding-right: $base5;
		}

		&::placeholder {
			color: inherit;
			opacity: 0.5;

			.header & {
				opacity: 0;

				@include media($mediumBreakpoint) {
					opacity: 1;
				}
			}

			.header.js-searchActive & {
				@include media($mediumBreakpoint) {
					opacity: 0.5;
				}
			}
		}
	}

	&__inputBox {
		flex-grow: 1;
		margin-right: $base2;
		position: relative;

		.header & {
			margin-right: 0;
		}
	}

	&__searchIcon {
		height: $base2;
		line-height: 0;
		pointer-events: none;
		position: absolute;
		right: $base;
		top: 50%;
		transform: translateY(-50%);

		.searchForm.js-busy & {
			visibility: hidden;
		}

		[data-type='svg'] {
			height: 100%;
			width: auto;
		}
	}

	&__searchBusyIcon {
		height: $base2;
		line-height: 0;
		pointer-events: none;
		position: absolute;
		right: $base;
		top: 50%;
		transform: translateY(-50%);
		visibility: hidden;
		width: $base2;

		.searchForm.js-busy & {
			visibility: inherit;
		}

		[data-type='svg'] {
			animation-duration: 1s;
			animation-iteration-count: infinite;
			animation-name: spin;
			animation-timing-function: linear;
			height: 100%;
			width: 100%;
		}
	}
}
