.listGrid {
	display: grid;
	padding-bottom: 1px;
	// when is touching the border of the blocks, the outline of the internal
	// links can be covered by prev / next block with higher z-index, so we
	// leave this gaps
	padding-top: 1px;
	position: relative;
	z-index: 1;

	&--regular {
		grid-template-columns: 1fr;

		@include media($columnsBreakpoint) {
			grid-template-columns: repeat(var(--columnsAmount), 1fr);
		}

		.block--listGridColumnsGapDefault & {
			column-gap: $gutter;
		}

		.block--listGridColumnsGapDouble & {
			column-gap: $gutter * 2;
		}

		.block--listGridRowsGapDefault & {
			row-gap: $gutter;
		}

		.block--listGridRowsGapDouble & {
			row-gap: $gutter * 2;
		}
	}

	&--adaptive {
		column-gap: 1px;
		position: relative;
		row-gap: 1px;

		@each $media, $values in $minTileSizes {
			@include media(#{'>=' + $media}) {
				@each $sizeName, $size in $values {
					@if $sizeName == 'small' {
						grid-template-columns: repeat(auto-fill, minmax(#{$size}, 1fr));
					} @else {
						.filterableList--productsOverview.js-largeTiles & {
							grid-template-columns: repeat(auto-fill, minmax(#{$size}, 1fr));
						}
					}
				}
			}
		}
	}

	&--mosaic {
		column-gap: $mosaicColumnsGutter;
		grid-template-columns: repeat(1, 1fr);
		row-gap: $mosaicRowsGutter;

		@include media($mediumBreakpoint) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include media($largeBreakpoint) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&--formatLink,
	&--formatSimpleLink {
		gap: 1px;
	}

	.block--colorSchemeInverted & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&__itemFigure {
		.listGrid--mosaic & {
			@include ease(opacity visibility, $duration6);
			grid-column: span 1;

			&.js-duringHidden {
				opacity: 0;
				visibility: hidden;
			}

			&.js-hidden {
				display: none;
			}

			// medium layout, groups of 3

			&[data-in-small-group-position='1'] {
				@include media($mediumBreakpoint) {
					grid-column: 1 / 3;
					grid-row: calc(1 + 4 * var(--mosaicSmallGroupIndex)) / calc(2 + 4 * var(--mosaicSmallGroupIndex));
				}
			}

			&[data-in-small-group-position='2'] {
				@include media($mediumBreakpoint) {
					grid-column: 1 / 2;
					grid-row: calc(3 + 4 * var(--mosaicSmallGroupIndex)) / calc(4 + 4 * var(--mosaicSmallGroupIndex));
				}
			}

			&[data-in-small-group-position='3'] {
				@include media($mediumBreakpoint) {
					grid-column: 2 / 3;
					grid-row: calc(3 + 4 * var(--mosaicSmallGroupIndex)) / calc(4 + 4 * var(--mosaicSmallGroupIndex));
				}
			}

			// large layout, groups of 6

			&[data-in-large-group-position='1'] {
				@include media($largeBreakpoint) {
					grid-column: 1 / 3;
					grid-row: calc(1 + 6 * var(--mosaicLargeGroupIndex)) / calc(4 + 6 * var(--mosaicLargeGroupIndex));
				}
			}

			&[data-in-large-group-position='2'] {
				@include media($largeBreakpoint) {
					grid-column: 3 / 4;
					grid-row: calc(1 + 6 * var(--mosaicLargeGroupIndex)) / calc(2 + 6 * var(--mosaicLargeGroupIndex));
				}
			}

			&[data-in-large-group-position='3'] {
				@include media($largeBreakpoint) {
					grid-column: 4 / 5;
					grid-row: calc(1 + 6 * var(--mosaicLargeGroupIndex)) / calc(2 + 6 * var(--mosaicLargeGroupIndex));
				}
			}

			&[data-in-large-group-position='4'] {
				@include media($largeBreakpoint) {
					align-self: end;
					grid-column: 3 / 5;
					grid-row: calc(3 + 6 * var(--mosaicLargeGroupIndex)) / calc(6 + 6 * var(--mosaicLargeGroupIndex));
				}
			}

			&[data-in-large-group-position='5'] {
				@include media($largeBreakpoint) {
					grid-column: 1 / 2;
					grid-row: calc(5 + 6 * var(--mosaicLargeGroupIndex)) / calc(6 + 6 * var(--mosaicLargeGroupIndex));
				}
			}

			&[data-in-large-group-position='6'] {
				@include media($largeBreakpoint) {
					grid-column: 2 / 3;
					grid-row: calc(5 + 6 * var(--mosaicLargeGroupIndex)) / calc(6 + 6 * var(--mosaicLargeGroupIndex));
				}
			}
		}
	}

	&__itemLink {
		.listGrid--mosaic & {
			color: currentColor;
			text-decoration: none;
		}
	}

	&__itemSubtitle {
		.listGrid--mosaic & {
			@include font(base);
			font-weight: normal;
		}
	}

	&__itemText {
		.listGrid--mosaic & {
			@include ease(opacity visibility, $duration6);
			grid-column: span 1;
			margin-top: -$mosaicRowsGutter;
			padding-top: $base2;
			position: relative;

			@include media($largeBreakpoint) {
				min-height: $base6 + $base2;
			}

			&.js-duringHidden {
				opacity: 0;
				visibility: hidden;
			}

			&.js-hidden {
				display: none;
			}

			// medium layout, groups of 3

			&[data-in-small-group-position='1'] {
				@include media($mediumBreakpoint) {
					grid-column: 1 / 3;
					grid-row: calc(2 + 4 * var(--mosaicSmallGroupIndex)) / calc(3 + 4 * var(--mosaicSmallGroupIndex));
				}
			}

			&[data-in-small-group-position='2'] {
				@include media($mediumBreakpoint) {
					grid-column: 1 / 2;
					grid-row: calc(4 + 4 * var(--mosaicSmallGroupIndex)) / calc(5 + 4 * var(--mosaicSmallGroupIndex));
				}
			}

			&[data-in-small-group-position='3'] {
				@include media($mediumBreakpoint) {
					grid-column: 2 / 3;
					grid-row: calc(4 + 4 * var(--mosaicSmallGroupIndex)) / calc(5 + 4 * var(--mosaicSmallGroupIndex));
				}
			}

			// large layout, groups of 6

			&[data-in-large-group-position='1'] {
				@include media($largeBreakpoint) {
					grid-column: 1 / 3;
					grid-row: calc(4 + 6 * var(--mosaicLargeGroupIndex)) / calc(5 + 6 * var(--mosaicLargeGroupIndex));
				}
			}

			&[data-in-large-group-position='2'] {
				@include media($largeBreakpoint) {
					grid-column: 3 / 4;
					grid-row: calc(2 + 6 * var(--mosaicLargeGroupIndex)) / calc(3 + 6 * var(--mosaicLargeGroupIndex));
				}
			}

			&[data-in-large-group-position='3'] {
				@include media($largeBreakpoint) {
					grid-column: 4 / 5;
					grid-row: calc(2 + 6 * var(--mosaicLargeGroupIndex)) / calc(3 + 6 * var(--mosaicLargeGroupIndex));
				}
			}

			&[data-in-large-group-position='4'] {
				@include media($largeBreakpoint) {
					grid-column: 3 / 5;
					grid-row: calc(6 + 6 * var(--mosaicLargeGroupIndex)) / calc(7 + 6 * var(--mosaicLargeGroupIndex));
				}
			}

			&[data-in-large-group-position='5'] {
				@include media($largeBreakpoint) {
					grid-column: 1 / 2;
					grid-row: calc(6 + 6 * var(--mosaicLargeGroupIndex)) / calc(7 + 6 * var(--mosaicLargeGroupIndex));
				}
			}

			&[data-in-large-group-position='6'] {
				@include media($largeBreakpoint) {
					grid-column: 2 / 3;
					grid-row: calc(6 + 6 * var(--mosaicLargeGroupIndex)) / calc(7 + 6 * var(--mosaicLargeGroupIndex));
				}
			}
		}
	}

	&__itemTitle {
		.listGrid--mosaic & {
			@include font(base);
			font-weight: bold;
		}
	}

	&__itemTextContent {
		.listGrid--mosaic & {
			position: relative;

			.listGrid__itemText[data-in-large-group-position='1'] & {
				@include media($largeBreakpoint) {
					transform: translateY(var(--mosaicHeightDiff, 0));
				}
			}
		}
	}
}
