.links {
	display: flex;
	flex-wrap: wrap;
	margin-left: -$base2; // horizontal gutter
	margin-top: ($base2 - $base2); // real marginTop - vertical gutter

	.block--hAlignLinksBegin > .block__container > .block__content & {
		justify-content: flex-start;
	}

	.block--hAlignLinksEnd > .block__container > .block__content & {
		justify-content: flex-end;
	}

	.block--hAlignLinksCenter > .block__container > .block__content & {
		justify-content: center;
	}

	.block--hAlignLinksJustified > .block__container > .block__content & {
		justify-content: space-between;
	}

	.block--colorSchemeInverted & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.block--submenu & {
		flex-direction: column;
		margin-top: 0;

		@include media($mediumBreakpoint) {
			flex-direction: row;
			justify-content: center;
			margin-left: $base * 12;
			margin-right: $base * 12;
		}
	}

	&__item {
		margin-left: $base2;
		margin-top: $base2;

		.block--submenu & {
			margin-left: 0;
			margin-top: 0;
			width: 100%;

			@include media($mediumBreakpoint) {
				width: auto;
			}

			&:not(:first-child) {
				border-top: 1px solid $colorBorder;

				@include media($mediumBreakpoint) {
					border-top: 0;
					margin-left: $base5;
				}

				@include media($columnsBreakpoint) {
					margin-left: $base8;
				}
			}
		}
	}
}
