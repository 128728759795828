.listLink {
	@include ease(opacity visibility, $duration6);
	display: flex;
	flex-direction: column;

	&.js-duringHidden {
		opacity: 0;
		visibility: hidden;
	}

	&.js-hidden {
		display: none;
	}

	.block--colorSchemeInverted & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&__content {
		display: flex;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		position: relative;
		z-index: 2;

		.listLink--withImage & {
			padding: ($base * 1.5) $base3;
		}

		.listLink--noImage & {
			align-items: center;
			justify-content: center;
			padding: ($base * 1.5) $base3;
		}

		&::before {
			@include ease(opacity visibility, $duration4);
			@include fadeOut();
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;

			.block--colorSchemeDefault & {
				background-color: $colorBgOverlayLinkGridItemDefault;
			}

			.block--colorSchemeAlternative & {
				background-color: $colorBgOverlayLinkGridItemAlternative;
			}

			.block--colorSchemeInverted & {
				background-color: $colorBgOverlayLinkGridItemInverted;
			}

			@include onHover('.listLink__link', ' &') {
				@include fadeIn();
			}
		}
	}

	&__figure {
		display: flex;
		flex-direction: column;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		position: relative;
		z-index: 1;
	}

	&__link {
		align-items: stretch;
		color: currentColor;
		display: grid;
		flex-grow: 1;
		outline: 1px solid $colorListGridLinkBorder;
		text-decoration: none;
	}

	&__title {
		letter-spacing: $listLinkLetterSpacing;
	}
}
