.network{
    margin-top: $base6;
    @include media('>small'){
        margin-top: $base10+3;
    }
    &__header{
        @include font(titleAlternative);
		color: $colorFocus;
		margin-bottom: $base2;
    }
    &__text{
        @include font(titleBigger);
    }
    &__note{
        margin-top: $base2;
        @include media('>small'){
            margin-top: $base3;
        }
	  	img {
		  width: 4rem;
		}
    }
    & strong{
        color: $colorFocus;
        font-weight: normal;
    }
}
