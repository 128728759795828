.menu {
	&__item {
		@include font(menuItem);

		&:not(:last-child) {
			margin-bottom: $base;
		}
	}

	&__items {
		.menu__sectionTitle + & {
			margin-top: $base2;
		}
		.menu--footerMenu > & {
			display: flex;
			flex-direction: column;
            gap: $base3;
			@include media('>small'){
				flex-direction: row;
				gap: $base*18;
			}
        }
	}

	&__link {
		color: currentColor;
		text-decoration: none;
	}

	&__sectionTitle {
		@include font(menuSectionTitle);
	}
}
