.listFilters {
	padding-bottom: $base2;
	position: relative;
	z-index: 2;

	* + & {
		margin-top: $base2;
	}

	&--sticky {
		@include media('>tileSizeMedium') {
			margin-top: $base2;
			position: sticky;
			top: 0;
		}
	}

	.block--colorSchemeInverted & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&__content {
		margin-bottom: $base5;
		position: relative;
	}

	&__controls {
		padding-bottom: $base;
		padding-top: $base;
		position: relative;

		.listFilters:not(.listFilters--textSearch) & {
			@include media('>tileSizeMedium') {
				display: grid;
				// this allow to center the filters in the page even if the other nodes on the side have different widths
				grid-template-columns: 1fr;
				justify-items: center;
			}
		}

		.block--hSizeFullNoContentPadding & {
			@include app-container($onlyPadding: true);
		}

		.filterableList--productsOverview .listFilters & {
			@include media('>tileSizeMedium') {
				grid-template-columns: 1fr auto 1fr;
			}
		}

		&::before {
			@include app-colorSchemes($baseClass: '.block', $nestedSelector: ' &');
			// background-color: $colorBgBase;
			content: '';
			height: 100%;
			left: 50%;
			margin-left: -50vw;
			position: absolute;
			right: 0;
			top: 0;
			width: 100vw;
			z-index: 0;
		}
	}

	&__filters {
		display: flex;
		flex-direction: column;

		@include media('>=tileSizeSmall') {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: left;
		}

		.listFilters:not(.listFilters--textSearch) & {
			@include media('>tileSizeMedium') {
				flex-wrap: nowrap;
				justify-content: center;
			}
		}
	}

	&__filtersResults {
		left: 0;
		margin-top: $base;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: 1;

		.block--hSizeFullNoContentPadding & {
			@include app-container($onlyPadding: true);
		}
	}

	&__radioField {
		@include font(customSelect);
		display: none;
		font-weight: bold;
		justify-self: start;
		position: relative;

		@include media('>=tileSizeSmall') {
			bottom: 100%;
			display: inline-flex;
			margin-bottom: $base4;
			position: absolute;
			right: $base5;
			transform: translateY(50%);
		}

		@include media('>tileSizeMedium') {
			bottom: auto;
			margin-bottom: 0;
			position: relative;
			right: auto;
			transform: none;
		}
	}

	&__radioFieldInput {
		@include visuallyHidden();
	}

	&__radioFieldLabel {
		align-items: center;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		padding: ($base * 1.5);
		position: relative;
		white-space: nowrap;
		z-index: 1;

		&::before {
			@include ease(background-color, $duration2);
			background-color: transparent;
			border: 1px solid currentColor;
			border-radius: 50%;
			content: '';
			height: $base;
			margin-right: $base * 1.5;
			width: $base;

			.listFilters__radioFieldInput:checked ~ & {
				background-color: currentColor;
			}
		}
	}

	&__radioFieldOption {
		border: 1px solid $colorCustomSelectOptionBorder;

		&:not(:last-child) {
			border-right: 0;
		}
	}

	&__sizes {
		bottom: 100%;
		margin-bottom: $base3;
		position: absolute;
		right: $blockHorizontalPaddingDefaultSmall;

		@include media('>tileSizeMedium') {
			align-self: center;
			bottom: auto;
			justify-self: end;
			margin-bottom: 0;
			position: relative;
			right: auto;
		}
	}

	&__tagItem {
		&--reset {
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	&__tags {
		display: flex;
		flex-wrap: wrap;
		height: 0; // do not take space, allowing the tags to overlap the items while scrolling
		justify-content: flex-start;
		margin-left: -$base;
		margin-top: -$base;
		padding-right: $base10;
		position: relative;
		width: calc(100% + #{$base});

		@include media('>tileSizeMedium') {
			justify-content: center;
			padding-left: $base10;
		}
	}
}
