.form {
	margin-top: $base6;	
	position: relative;

	&__actions {
		display: flex;
		justify-content: flex-end;
		margin-bottom: -$base2;
		// margin-left: -$base2;

		* + & {
			margin-top: $base3;
		}

		.form__separator + & {
			margin-top: $base4;
		}

		.form.js-success & {
			display: none;
		}
	}

	&__fields {
		@include grid(12, $gutter, $gutter * 2);
		position: relative;

		.form.js-success & {
			display: none;
		}
	}

	&__message {
		margin-top: $base;

		&[data-message-type='error'] {
			color: $colorError;
		}

		&[data-message-type='success'] {
			color: $colorFocus;
		}
	}

	&__messages {
		@include font(base);
		font-weight: bold;
	}

	&__separator {
		background-color: currentColor;
		border: 0;
		display: block;
		height: $boxBorderSize;
		margin-bottom: 0;
		width: 100%;

		.form__fields & {
			grid-column-start: span 12;
		}

		* + & {
			margin-top: $base4;
		}
	}

	&__text {
		.form__fields & {
			grid-column-start: span 12;
		}

		.form__buttons & {
			@include font(base);
			margin: $base3 0 $base3 $base2;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.form > & {
			margin-bottom: $base3;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.form > * + & {
			margin-top: $base3;
		}

		.form__separator + & {
			margin-top: $base4;
		}
	}
}
