.gridHelper {
	background-color: rgba(0, 0, 0, 0.015);
	display: none;
	height: 100%;
	left: 0;
	pointer-events: none;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 200;

	&.js-active {
		display: block;
	}

	&::after {
		content: '';
		background-attachment: fixed;
		background-image: repeating-linear-gradient(180deg, #fff 0, #fff 0.25 * 10rem, #000 0.25 * 10rem, #000 0.5 * 10rem);
		background-position: top;
		height: 100%;
		left: 0;
		opacity: 0.025;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&__content {
		@include app-container();
		height: 100%;
		background-color: rgba(0, 244, 0, 0.01);
	}

	&__columns {
		@include staticColumns();
		height: 100%;
	}

	&__column {
		background-color: rgba(255, 0, 0, 0.05);
		width: staticColumnSize(1, 6, $gutterSmall);
		height: 100%;

		&:nth-child(n+7) {
			display: none;

			@include media($mediumBreakpoint) {
				display: block;
			}
		}

		@include media($mediumBreakpoint) {
			width: staticColumnSize(1, 12, $gutterSmall);
		}

		// @include media($largeBreakpoint) {
		// 	width: staticColumnSize(1, 12, $gutterLarge);
		// }

		&:nth-child(n+13) {
			display: none;
		}
	}
}
