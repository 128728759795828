/*
  Sets the object-fit and can set the object-position to honor variable --focusX and --focusY, if they are defined
*/
@mixin objectFit($fit: fill, $position: null) {
	object-fit: $fit;
	$defaultX: 50%;
	$defaultY: 50%;
	@if $position {
		$defaultX: nth($position, 1);
		$defaultY: if(length($position) == 2, nth($position, 2), nth($position, 1));
	}

	object-position: var(--focusX, #{$defaultX}) var(--focusY, #{$defaultY});
}
