.squareButton {
	cursor: pointer;
	color: $colorFocus;
	background-color: $colorInverted;
	border: none;
	// height: $formElementsHeight;
	border-radius: 0.5 * $formElementsHeight;
	padding: $base*0.9 $base*2.5;
	// display: inline-flex;
	// justify-content: center;
	// align-content: center;
	// flex-direction: column;
	// margin: auto;
	width: 100%;
	text-decoration: none;

	@include media('>medium'){
		width: fit-content;
	}

	&:hover {
		background-color: $colorBrightFocus;
		color: $colorInverted;
	}


	&__label {
		font-weight: 500;
		// vertical-align: middle;
		display: inline-block;
		width: auto;
		position: relative;
		margin-top: -1px; //just to align nicer
		z-index: 1;

	}

	&--inverted {
	  background-color: $colorFocus;
	  color: $colorInverted;
	}
}
