$colorBase: #000000 !default;
$colorBgBase: #FFFFFF !default;
$colorInverted: $colorBgBase !default;
$colorBgInverted: $colorBase !default;
$colorAlternative: $colorBase !default;
$colorBgAlternative: #F8F8FB !default;

$colorFocus: #5900EB;
$colorBorder: #C1C1C1;
$colorSubtext: #606060;
$colorBrightFocus: #8a4cf1;

$textSecondaryOpacity: 0.8;


$colorBgButtonAlternativeHover: #CCCCCC;
$colorBgButtonAlternativeInvertedHover: #333333;

$colorBgSubmenu: #191919;

$colorBorderListTile: #E6E6E6;

$colorBgHeaderOverContent: (
	default: $colorBgBase,
	alternative: $colorBgBase,
	inverted: $colorBgInverted
);


$colorBgModal:$colorBgInverted !default;


$colorInputBorder: $colorBorder !default;
$colorError: #FF0000 !default;
$colorSuccess: lime !default;

$colorBgModalCover: transparentize($colorBgInverted, 0.4) !default;


$colorCustomSelectOptionBorder: $colorBgAlternative;
$colorCustomSelectOptionBorderAlternative: $colorBorder;

$colorBgCustomSelectReset: $colorBgAlternative;
$colorCustomSelectReset: #959595;

$colorFilterTag: $colorBase;
$colorBgFilterTag: $colorBgAlternative;
$colorFilterTagAlternative: $colorInverted;
$colorBgFilterTagAlternative: $colorBorder;


$colorProductsOverviewGridBorder: $colorBgAlternative;  // #606060;
$colorProductsOverviewInactiveMenu: #777777;

$colorScrollBarBgTrack: transparent;
$colorScrollBarBgHandle: #939393;

$colorSlideshowDirection: $colorBase;
$colorBgSlideshowDirection: rgba(0, 0, 0, 0.2);

$colorSlideshowDirectionInverted: $colorInverted;
$colorBgSlideshowDirectionInverted: rgba(255, 255, 255, 0.2);


$colorBgOverlayLinkGridItemDefault: rgba(0, 0, 0, 0.05);
$colorBgOverlayLinkGridItemAlternative: rgba(0, 0, 0, 0.05);
$colorBgOverlayLinkGridItemInverted: rgba(255, 255, 255, 0.1);

$colorListGridLinkBorder: $colorBorder;

$colorSchemes: (
	default: (
		background-color: $colorBgBase,
		color: $colorBase,
	),

	inverted: (
		background-color: $colorBgInverted,
		color: $colorInverted,
	),

	alternative: (
		background-color: $colorBgAlternative,
		color: $colorAlternative,
	),

	defaultText: (
		background-color: transparent,
		color: $colorBase,
	),

	invertedText: (
		background-color: transparent,
		color: $colorInverted,
	)
);
