.slide {
	display: flex;
	flex-direction: row;
	flex-grow: 0;
	flex-shrink: 0;
	padding-right: $slideGap;
	position: relative;
	width: 100%;

	.slideshow--animationTypeFade & {
		@include ease(opacity visibility, $slideshowFadeDuration);
		@include fadeOut();
		z-index: 1;

		@for $index from 0 through ($slideshowMaxItems - 1) {
			&:nth-child(#{$index + 1}) {
				transform: translateX(#{-100% * $index});
			}
		}

		&.js-current {
			@include fadeIn();
			z-index: 2;
		}
	}

	.slideshow--animationTypeSlide .slideshow__items.js-animate & {
		@include ease(transform, $slideshowSlideDuration);
	}

	.slideshow--animationTypeSlide.slideshow--circular & {
		$balancedLimit: ceil($slideshowMaxItems * 0.5);

		@for $index from 0 through ($slideshowMaxItems - 1) {
			@for $position from 0 through $balancedLimit {
				&:nth-child(#{$index + 1})[data-balanced-position='#{$position}'] {
					transform: translateX(#{-100% * ($index - $position)});
				}

				&:nth-child(#{$index + 1})[data-balanced-position='-#{$position}'] {
					transform: translateX(#{-100% * ($index + $position)});
				}
			}
		}
	}

	.slideshow--animationTypeSlide:not(.slideshow--circular) & {
		@for $index from 0 through ($slideshowMaxItems - 1) {
			@for $position from 0 through ($slideshowMaxItems - 1) {
				&:nth-child(#{$index + 1})[data-position='#{$position}'] {
					transform: translateX(#{-100% * ($index - $position)});
				}

				&:nth-child(#{$index + 1})[data-position='-#{$position}'] {
					transform: translateX(#{-100% * ($index + $position)});
				}
			}
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		flex-shrink: 0;
		order: 2;
		padding: $blockVerticalSpacing 0;
		position: relative;
		width: 100%;
		z-index: 1;

		// making the text selectable only when you are touching the text elements
		> * {
			.slide:active & {
				user-select: none;
			}

			.slide:active &:active {
				user-select: auto;
			}
		}

		// .block--hPositionBegin > & {
		// 	align-items: flex-start;
		// }

		// .block--hPositionCenter > & {
		// 	align-items: center;
		// }

		// .block--hPositionEnd > & {
		// 	align-items: flex-end;
		// }

		// .block--vAlignBegin > & {
		// 	justify-content: flex-start;
		// }

		// .block--vAlignEnd > & {
		// 	justify-content: flex-end;
		// }

		// .block--vAlignCenter > & {
		// 	justify-content: center;
		// }

		// .block--vAlignJustified > & {
		// 	justify-content: flex-start;
		// 	// the content will decide who is taking space and who is not
		// }

		// .block--minHeightBg > & {
		// 	transform: translateX(-100%);
		// }
	}

	&__content {
		@include app-container();
	}

	&__figure {
		flex-grow: 0;
		flex-shrink: 0;
		height: auto;
		// opacity: $teaserBgOpacity;
		order: 1;
		padding-right: $slideGap;
		width: 100%;
		z-index: 0;

		.slideshow:not(.slideshow--minHeightBg) & {
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}
