.facts{
    margin-top: $base6;
    padding: $base2 0 $base6 0;
    border-top: 1px solid $colorFocus;
    border-bottom: 1px solid $colorFocus;
    @include media('>small'){
        padding: $base5 0 $base10+3 0;
        margin-top: $base10+2;
    }
    &__header{
        @include font(titleAlternative);
		color: $colorFocus;
		margin-bottom: 0;
        @include media('>small'){
            margin-bottom: $base2;
        }
    }

    &__items{}

    &__text{
        @include font(titleBiggest);
        padding: $base2 0;
        width: 100%;
        border-top: 1px solid $colorBorder;
        font-weight: bold;
        &:first-child{
            border-top: none;
        }
        @include media('>small'){
            padding: $base3 0;
        }

        & strong{
            color: $colorFocus;
        }
    }
}
