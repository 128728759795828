.cover {
	//margin-bottom: $base6;
	min-height: 500px;
	padding-top: $base * 12;
	position: relative;

	@include media('>medium'){
		//margin-bottom: $base6;
	}

	&__backgroundContent {
		position: absolute;
		top: 0;
		left: 0;
		width: 200%;
		@include media ('>small'){
			width: 100%;
		}
	}

	&__backgroundImage {
		position: relative;
		width: 100%;
	}

	&__backgroundImg {
		width: 100%;
		position: absolute;
		// left: 50%;
		// transform: translateX(-50%) translateY(-3vw);
	}


	&__scrollDown {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	&__container {
		@include app-container();
	}

	&__content{
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		position: relative;
		gap: $base4;
		&::before {
			display: none;
		}
		@include media('>medium'){
			flex-direction: row;
			justify-content: space-between;
			gap: $base1;
		}
		@include media('>xLarge'){
			gap: $base5;
		}
	}

	&__subhead{
		font-weight: bold;
		letter-spacing: 0.2rem;
		margin-bottom: $base*1.5;
		text-transform: uppercase;
	}

	&__title{
		@include font(titleBiggest);
		font-weight: bold;
		letter-spacing: -0.1rem;
		margin-bottom: $base4;
	}

	&__text{
		width: 100%;
		// & p{
		// 	margin-bottom: $base2;
		// }
	}

	&__left {
		width: 100%;
		@include media('>medium'){
			width: 60%;
		}
	}

	&__right{
		text-align: center;
		@include media('>medium'){
			text-align: unset;
		}
	}
	&__image{
		position: relative;
		@include media('>medium'){
			height: $base*50;
		}
		@include media('>large'){
			height: $base*70;
		}
		& img{
			width: 100%;
			max-width: inherit;
			@include media('>medium'){
				position: absolute;
				top: $base4;
				left: -$base*10;
				width: 700px;
			}
			@include media('>large'){
				position: absolute;
				top: 0;
				left: -$base*20;
				width: 1000px;
			}
		}
	}

	&__imageMain{
		display: none;
		@include media('>small'){
			display: block;
		}
	}

	&__imageAdditional{
		display: block;
		@include media('>small'){
			display: none;
		}
	}

	&__link{
		@include font(baseSmaller);
		text-align: center;
		@include media('>medium'){
			text-align: right;
		}
		& a{
			color: $colorFocus;
			text-decoration: none;
		}
	}

}
