.singupPopup {
	@include ease(transform, $durationBase);
	background-color: $colorFocus;
	bottom: 0;
	color: $colorInverted;
	left: 0;
	min-height: $base*9;
	position: fixed;
	transform: translateY(100%);
	width: 100%;
	z-index: 100;

	&.js-active {
		transform: translateY(0);
	}

	&__content {
		@include app-container();
		padding-top: $base1;
		padding-bottom: $base1;
		position: relative;
		@include media('>small'){
			padding-top: $base2;
			padding-bottom: $base3;
		}
	}

	&__closeButton {
		position: absolute;
		top: $base2;
		right: $base2;
		cursor: pointer;
	}

	&__closeIcon {
		//
	}

	&__form{}

	&__formTitle {
		padding-bottom: $base * 0.2;
	}

	&__formSelected {
		@include font(baseSmaller);
	}
	
	&__formSelectedTitle{
		@include font(searchTitleHeader);
	}

	&__formSelectedList {
		@include font(baseSmaller);
		margin-left: $base;
		text-transform: uppercase;
	}

	&__viewForm {
		display: flex;
		flex-direction: column;
		gap: $base1;
		@include media('>small'){
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-right: $base3;
			gap: $base2;
		}
		@include media('>medium'){
			margin-right: 0;
		}
		
	}
	
	&__text{
		flex-basis: 100%;
		padding-right: $base2;
		@include media('>small'){
			flex-basis: 60%;
			padding-right: 0;
		}
		@include media('>medium'){
			flex-basis: 50%;
		}
	}

	&__viewThankyou {
		display: none;
	}
}
