.menuToggler {
	@mixin line() {
		backface-visibility: hidden;
		background-color: currentColor;
		height: $menuTogglerLineSize;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	@include ease(transform, $duration2);
	@include line();
	display: inline-block;
	font-size: 0;
	position: relative;
	text-indent: -10000px;
	width: $menuTogglerWidth;

	//transform: translateY(#{$menuTogglerLineSpace + $menuTogglerLineSize});
	.button.js-toggled & {
		background-color: transparent;
	}

	@include onHover('.button.js-toggled', ' &') {
		transform: scale(#{$menuTogglerScaleHover});
	}

	&::before {
		@include line();
		@include ease(transform, $duration2);
		content: '';
		transform: translateY(-#{$menuTogglerLineSpace + $menuTogglerLineSize});

		@include onHover('.button:not(.js-toggled)', ' &') {
			transform: translateY(-#{$menuTogglerLineSpaceHover + $menuTogglerLineSize});
		}

		.button.js-toggled & {
			transform: translateY(0) rotate(45deg);
		}
	}

	&::after {
		@include line();
		@include ease(transform, $duration2);
		content: '';
		transform: translateY(#{($menuTogglerLineSpace + $menuTogglerLineSize)});

		@include onHover('.button:not(.js-toggled)', ' &') {
			transform: translateY(#{$menuTogglerLineSpaceHover + $menuTogglerLineSize});
		}

		.menuToggler.js-toggled &,
		.button.js-toggled & {
			transform: translateY(0) rotate(-45deg);
		}
	}
}
