.videoPlayer {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	&--bg {
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: -1;
	}

	&--bg::after {
		@include app-colorSchemes($baseClass: '.block', $nestedSelector: ' > .block__bg &');
		content: '';
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;

		.block--bgSoftened > .block__bg & {
			opacity: $blockBgCoverOpacity;
		}
	}

	&__embed {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.block__bg--video & {
			@include objectFit(cover);
		}

		> iframe,
		> video {
			border: 0;
			height: 100%;
			width: 100%;
		}
	}
}
