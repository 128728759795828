.slideshowNavigation {
	&__arrows {
		@include ease(opacity visibility, $duration2);
		@include fadeOut();
		align-items: center;
		display: flex;
		flex-direction: row;
		height: 100%;
		justify-content: space-between;
		left: 0;
		padding: 0 $base5;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;

		.slideshow__slidesContainer:hover & {
			@include fadeIn();
		}

		.block--productModels & {
			height: auto;
			padding: 0;
			@include fadeIn();
		}
	}

	&__triggers {
		display: flex;
		margin-top: $base;

		.slideshowNavigation--withTitles & {
			flex-wrap: wrap;
			margin-top: 0;
		}
	}

	&__trigger {
		flex-grow: 1;

		&:not(:last-child) {
			margin-right: $base;
		}

		.slideshowNavigation--withTitles & {
			flex-grow: 0;
			margin-top: $base;
		}
	}
}
