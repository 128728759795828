.singupForm {
	display: flex;
	gap: $base1;
	flex-direction: column;
	@include media('>medium'){
		flex-direction: row;
		margin-right: $base1;
	}
	@include media('>small'){
		gap: $base2;
	}
	&__additionalText {
		@include font(baseSmaller);
		margin-top: $baseHalf;
		opacity: $textSecondaryOpacity;
		display: none;
		@include media('>small'){
			display: block;
		}
		&--mobile{
			display: block;
			@include media('>small'){
				display: none;
			}
		}
	}

	&__button {
		// margin-left: $base2;
		white-space: nowrap;
	}

	&__input {
		width: 100%;
		display: inline-block;
		@include media('>small'){
			width: $base * 28;
		}
		@include media('>medium'){
			width: $base * 35;
		}
	}
}
