.checkbox {
	&__on {
		display: none;

		.topic.js-active & {
			display: block;
			color: transparent;
		}
	}

	&__off {
		display: block;

		.topic.js-active & {
			display: none;
		}
	}
}
