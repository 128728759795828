.video {
	@mixin hideVideoCoverWhenReady() {
		@include ease(opacity visibility, $duration2);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.video.js-loading & {
			@include fadeOut();
		}

		.video.js-loaded & {
			display: none;
		}
	}

	.block--twoContents &--content {
		grid-area: figure;
	}

	&__caption {
		@include font(caption);
		margin-top: $base;
	}

	&__content {
		@include aspectRatio($videoRatio);
		position: relative;

		// useful to test the source switch of self-hosted video player
		// @include media('portrait') {
		// 	@include aspectRatio(1 / $videoRatio);
		// }
	}

	&__icon {
		height: $base4;
		left: 50%;
		margin-left: -$base2;
		margin-top: -$base2;
		position: absolute;
		top: 50%;
		width: $base4;

		[data-type='svg'] {
			height: 100%;
			width: 100%;
		}
	}

	&__img {
		@include hideVideoCoverWhenReady();
		@include objectFit(cover);
	}

	&__link {
		@include hideVideoCoverWhenReady();
		cursor: pointer;
	}
}
