@mixin grid($columns, $columnGap: null, $rowGap: null) {
	display: grid;
	grid-template-columns: repeat(#{$columns}, 1fr);

	@if $columnGap != null {
		column-gap: $columnGap;
	}
	@if $rowGap != null {
		row-gap: $rowGap;
	}
}
