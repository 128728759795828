.scrollDownIndicator {
	width: 12px;
	cursor: pointer;
	z-index: 3;

	display: none;


	@include media($columnsBreakpoint) {
		display: block;
	}

	&::before,
	&::after {
		content: '';
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	&::before {
		width: 7px;
		height: 7px;
		border-right: 1px solid $colorFocus;
		border-bottom: 1px solid $colorFocus;
		transform: rotate(45deg);
		animation: arrow 3s infinite ease-in-out;
	}

	&::after {
		width: 7px;
		height: 7px;
		border-right: 1px solid $colorFocus;
		border-bottom: 1px solid $colorFocus;
		transform: rotate(45deg);
		animation: arrow 3s infinite ease-in-out;
		animation-delay: .75s;
		//opacity: .25;
	}

	&__dots {
		display: block;
		&::before,
		&::after {
			content: '';
			display: block;
			margin-left: auto;
			margin-right: auto;
		}

		&::before {
			width: 7px;
			height: 7px;
			border-right: 1px solid $colorFocus;
			border-bottom: 1px solid $colorFocus;
			transform: rotate(45deg);
			animation: arrow 3s infinite ease-in-out;
			animation-delay: .25s;
		}

		&::after {
			width: 7px;
			height: 7px;
			border-right: 1px solid $colorFocus;
			border-bottom: 1px solid $colorFocus;
			transform: rotate(45deg);
			animation: arrow 3s infinite ease-in-out;
			animation-delay: .5s;
		}
	}
}

@keyframes arrow {
	0% {
		transform: scale(.75) rotate(45deg);
		opacity: .25;
	}
	25% {
		transform: scale(1) rotate(45deg);
		opacity: 1;
	}
	100% {
		transform: scale(.75) rotate(45deg);
		opacity: .25;
	}
}
