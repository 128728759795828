.pagination {
	margin-left: -$base2;
	margin-top: -$base;
	padding-top: $base6;
	position: relative;

	&__item {
		display: none;
		margin-left: $base2;
		margin-top: $base;

		@include media($columnsBreakpoint) {
			display: inline-block;
		}

		&--arrow {
			display: inline-block;
		}
	}

	&__items {
		display: flex;
		justify-content: space-between;
		// height: $base6;

		@include media($columnsBreakpoint) {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
}
