@use "sass:math";

$base: 1rem !default;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;
$baseHalf: $base * 0.5;

$gridColumnsSmall: 1;
$gridColumnsLarge: 12;

$videoRatio: math.div(16, 9) !default;

$gutterSmall: $base2;

$minTileHeight: 31.3 * $base;
$minTileHeightSmall: 15 * $base;

$formElementsHeight: 4.3 * $base;
$formFieldPadding: 2.5 * $base;
$buttonPadding: 1.6 * $base;


$blockHorizontalPaddingDefaultSmall: $base * 1.6 !default;
$blockHorizontalPaddingDefaultLarge: $blockHorizontalPaddingDefaultSmall * 2 !default;

$blockVerticalPaddingDefaultSmall: $base * 3.2 !default;
$blockVerticalPaddingDefaultLarge: $base * 4.8 !default;
$blockVerticalPaddingExtendedSmall: $base * 4.8 !default;
$blockVerticalPaddingExtendedLarge: $base8 !default;

$logoWidth: $base7;
$headerHeight: $base * 6.4;
$headerTopPadding: 0; //$base * 1.6;

$subMenuHeight: $base * 6;

$maxWidthValues: (
	text: 45 * $base,
	largerText: 67.5 * $base,
	content: 124 * $base,
	full: none,
	fullNoContentPadding: none
);

$textMaxWidth: map-get($maxWidthValues, largerText) !default;



$fixedMinHeightValues: (
	squareRatio: 100%,
	viewportHeight: 100vh,
	viewportHeightMinSubmenu: calc(100vh - #{$subMenuHeight})
);

// values with 'multi' prefix are applied to multi-column layouts, the others to single columns.
// the order is important, multi section should come after the base one.
$blockPaddingValues: (
	reset: (
		none: (
			value: 0
		),
		multiNone: (
			variants: (
				$columnsBreakpoint: (
					value: 0
				)
			)
		)
	),
	horizontal: (
		default: (
			value: $blockHorizontalPaddingDefaultSmall
		),
		multiDefault: (
			variants: (
				$columnsBreakpoint: (
					value: $blockHorizontalPaddingDefaultLarge
				)
			)
		)
	),
	vertical: (
		default: (
			value: $blockVerticalPaddingDefaultSmall
		),
		multiDefault: (
			variants: (
				$columnsBreakpoint: (
					value: $blockVerticalPaddingDefaultLarge
				)
			)
		),
		extended: (
			value: $blockVerticalPaddingExtendedSmall
		),
		multiExtended: (
			variants: (
				$columnsBreakpoint: (
					value: $blockVerticalPaddingExtendedLarge
				)
			)
		)
	),
	firstLine: (
		default: (
			value: $blockVerticalPaddingDefaultSmall + $headerHeight
		),
		multiDefault: (
			variants: (
				$columnsBreakpoint: (
					value: $blockVerticalPaddingDefaultLarge + $headerHeight
				)
			)
		),
		extended: (
			value: $blockVerticalPaddingExtendedSmall + $headerHeight
		),
		multiExtended: (
			variants: (
				$columnsBreakpoint: (
					value: $blockVerticalPaddingExtendedLarge + $headerHeight
				)
			)
		)
	)
);


$twoContentsGaps: (
	horizontal: (
		none: (
			value: 0,
			variants: (
				$columnsBreakpoint: (
					value: 0
				)
			)
		),
		default: (
			value: $blockHorizontalPaddingDefaultSmall,
			variants: (
				$columnsBreakpoint: (
					value: $blockHorizontalPaddingDefaultLarge
				)
			)
		),
		double: (
			value: $blockHorizontalPaddingDefaultSmall * 2,
			variants: (
				$columnsBreakpoint: (
					value: $blockHorizontalPaddingDefaultLarge * 2
				)
			)
		)
	),
	vertical: (
		none: (
			value: 0,
			variants: (
				$columnsBreakpoint: (
					value: 0
				)
			)
		),
		default: (
			value: $blockVerticalPaddingDefaultSmall,
			variants: (
				$columnsBreakpoint: (
					value: $blockVerticalPaddingDefaultLarge
				)
			)
		),
		double: (
			value: $blockVerticalPaddingDefaultSmall * 2,
			variants: (
				$columnsBreakpoint: (
					value: $blockVerticalPaddingDefaultLarge * 2
				)
			)
		),
		extended: (
			value: $blockVerticalPaddingExtendedSmall,
			variants: (
				$columnsBreakpoint: (
					value: $blockVerticalPaddingExtendedLarge
				)
			)
		),
		doubleExtended: (
			value: $blockVerticalPaddingExtendedSmall * 2,
			variants: (
				$columnsBreakpoint: (
					value: $blockVerticalPaddingExtendedLarge * 2
				)
			)
		)
	)
);


$blockBorderSize: 1px;


$menuTogglerLineSize: 1px;
$menuTogglerLineSpace: 4px;
$menuTogglerLineSpaceHover: 6px;
$menuTogglerWidth: pxToRem(20); //, 1);
$menuTogglerHeight: $menuTogglerLineSpace * 2 + $menuTogglerLineSize * 3;
$menuTogglerScaleHover: 1.2;


$blockVerticalSpacing: $base4 !default;
$firstBlockTopSpacing: $base8 !default;
$teaserMinHeight: 80vh !default;
$teaserMinHeightIeFix: 79vh !default; // 1 unit less than the previous

$gutter: $base * 1.6 !default;
$layoutGutter: $gutter !default;
$mosaicColumnsGutter: $gutter;
$mosaicRowsGutter: $gutter * 2;

$mosaicFigureRatio: 3 * 0.5;

$galleryJustifiedItemMinHeight: $base * 30 !default;
$galleryColumnsNumber: 2 !default;
$galleryColumnsImageRatio: 3 * 0.5 !default;

$slideshowMaxItems: 20 !default;

$slideMinHeight: 60vh !default;
$slideRatio: math.div(16, 9) !default;
$slideGap: $gutter !default;

$customScrollbarWidth: $baseHalf;
$customSelectMaxOptionsHeight: $base * 25;

$inputPaddingHorizontal: $base2 !default;
$inputPaddingVertical: ($base * 1.3) !default;

$inputNestedPadding: $base * 1.5 !default;
$labelNestedPadding: $base * 0.4 !default;
$inputToLabelVerticalMargin: $baseHalf !default;
$inputBorderSize: 1px !default;
$buttonBorderSize: $inputBorderSize !default;
$buttonMinWidth: $base * 16 !default;
$radioOuterSize: $base2 !default;
$radioInnerSize: $base * 1.2 !default;
$radioInnerScale: 0.5 !default;
$boxBorderSize: 2px !default;

$formFieldFullColumns: 8 !default; // number of columns, 12 = full width

$mapHeightSmall: 50 * $base !default;
$mapHeightLarge: 50 * $base !default;

$hrSize: 1px !default;


$tileRatio: math.div(1, 1.618);

$minTileSizes: (
	tileSizeExtraSmall: (
		small: 150px,
		large: 300px
	),
	tileSizeSmall: (
		small: 150px,
		large: 250px
	),
	tileSizeMedium: (
		small: 170px,
		large: 250px
	),
	tileSizeLarge: (
		small: 250px,
		large: 300px
	),
	tileSizeExtraLarge: (
		small: 250px,
		large: 360px
	)
);

$listLinkLetterSpacing: pxToRem(0.2);
