
@mixin app-toggleContainer($apply: true, $onlyPadding: false) {
	$values: map-get($blockPaddingValues, horizontal);
	@if $apply {
		$contentMaxWidth: map-get($maxWidthValues, content);
		@include mediaMap($values, default, padding-left);
		@include mediaMap($values, default, padding-right);
		@include mediaMap($values, multiDefault, padding-left);
		@include mediaMap($values, multiDefault, padding-right);
		@if $onlyPadding != true {
			$defaultValues: map-get($values, default);
			$value: map-get($defaultValues, value);
			max-width: ($contentMaxWidth + $value * 2);
			@if map-get($defaultValues, variants) {
				@each $media, $mediaValues in map-get($defaultValues, variants) {
					$value: map-get($mediaValues, value);
					@include media($media) {
						max-width: ($contentMaxWidth + $value * 2);
					}
				}
			}
			$multiDefaultValues: map-get($values, multiDefault);
			@if map-get($multiDefaultValues, variants) {
				@each $media, $mediaValues in map-get($multiDefaultValues, variants) {
					$value: map-get($mediaValues, value);
					@include media($media) {
						max-width: ($contentMaxWidth + $value * 2);
					}
				}
			}
		}
	} @else {
		padding-left: 0;
		padding-right: 0;
		max-width: none;
	}
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}
