
@mixin app-colorSchemes($baseClass: '&', $nestedSelector: '') {
	@each $name, $values in $colorSchemes {
		$modifier: $baseClass + '--' + 'colorScheme' + ucFirst($name);
		$selector: $modifier + $nestedSelector;

		#{$selector} {
			@include mediaMap($colorSchemes, $name);
		}
	}
}
