.principles{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: $base3;
	text-align: left;
	padding: $base6 0;
	align-items: center;
	@include media('>medium'){
		flex-direction: row;
		padding: $base10+3 0;
		gap: $base10;
	}

	&__header{
		@include font(titleAlternative);
		color: $colorFocus;
		margin-bottom: $base2;
	}
	&__text{
		@include font(titleBigger);
	}
	&__textMain{
		color: $colorBase;
	}
	&__textAccent{
		color: $colorFocus;
	}
	&__picture{}

	&__pictureIcon{
		width: 100%;
		@include media('>small'){
			width: auto;
		}
	}

	&__block{}
}
