.question{
	display: flex;
	flex-direction: row;
    margin-top: $base2;
    align-items: flex-end;
    gap: $baseHalf;
    &__text{
        @include font(questionText);

        & a{
            color: $colorFocus;
            text-decoration: none;
        }
    }

    &__icon{
        & img{
            width: 100%;
            max-width: $base*3.5;
        }
    }
}
