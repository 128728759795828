$duration: 0.1s !default;
$duration2: $duration * 2;
$duration3: $duration * 3;
$duration4: $duration * 4;
$duration5: $duration * 5;
$duration6: $duration * 6;
$duration7: $duration * 7;
$duration8: $duration * 8;
$duration9: $duration * 9;
$duration10: $duration * 10;


$easeLinear: linear;
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

$durationBase: $duration3;


$blockBgOpacity: 0.6 !default;
$blockBgCoverOpacity: 1 - $blockBgOpacity;

$opacityInputDisabled: 0.3 !default;

$slideshowFadeDuration: $duration4 !default;
$slideshowSlideDuration: $duration5 !default;

$customSelectImplicitCheckOpacity: 0.3;

$slideshowTriggerNotCurrentOpacity: 0.3;
