.logosMarquee {
	display: block;
	width: 100vw;
	overflow: hidden;
	text-align: left;
	position: relative;
	margin-top: $base3;
	@include media('>small'){
		margin-top: $base6;
	}

	&__container {
		// display: block;
		// overflow: hidden;
		// position: relative;
		// width: 100%;
	}

	&__contentImages {
		display: inline-block;
		white-space: nowrap;
		width: max-content;
	}

	&__content {
		display: inline-block;
		white-space: nowrap;
		width: max-content;
	}

	&__image {
		//align-items: baseline;
		filter: saturate(0);
		display: inline-flex;
		flex-wrap: nowrap;
		white-space: nowrap;
		height: 100%;
		margin: 0 $base8;
	}

	&__img {
		//width: 100%;
		height: $base * 6;
	}
}
