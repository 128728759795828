// it sets the size of the element in a fixed aspect-ratio
// It requires an absolute-positioned wrapper for the content

// $ratio is defined as width / height, like 16 / 9 or 1 (a square)
@use "sass:math";

@mixin aspectRatio($ratio, $pseudoElement: 'before') {
	$ratioValue: if(type-of($ratio) == number, math.div(100%, $ratio), $ratio);

	&::#{$pseudoElement} {
		content: '';
		display: block;
		padding-bottom: $ratioValue;
	}
}
