.benefits{
	text-align: left;
	padding: $base6 0;
	@include media('>small'){
		padding: $base10 0;
	}
	&__header{
		@include font(titleAlternative);
		color: $colorFocus;
		margin-bottom: $base2;
	}

	&__items{
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		column-gap: $gutterSmall;
		row-gap: $gutterSmall;
		@include media('>small'){
			grid-template-columns: repeat(2, 1fr);
		}
		@include media('>medium'){
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.benefit{
	&__item{
		@include font(titleAlternative);
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		background: $colorBgBase;
		padding: $base4;
	}
	&__icon{
		width: $base10;
		@include media('>small'){
			width: $base10+4;
		}
	}
	&__header{
		margin-top: $base2;
	}
}
