.searchResults {
	display: flex;
	// grid-template-columns: 60% auto;
	// column-gap: $;

	* + & {
		margin-top: $base3;

		.header & {
			margin-top: 0;
		}
	}

	.block--colorSchemeInverted & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&__section {
		flex-grow: 1;

		&--pages {
			border-right: 1px solid $colorBorder;
			flex-grow: 0;
			flex-shrink: 0;
			margin-right: $base2;
			padding-right: $base2;
			width: 65%;
		}
	}

	.header & {
		left: 0;
		position: absolute;
		top: 100%;
		width: 100%;

		@each $schemeName, $value in $colorBgHeaderOverContent {
			$selector: '.body--colorScheme' + ucFirst($schemeName) + ' &';

			#{$selector} {
				background-color: $value;
			}
		}
	}

	.header:not(.js-searchActive) & {
		display: none;
	}

	&__amount {
		@include font(baseSmaller);
		font-weight: bold;

		.header & {
			@include font(searchTitleHeader);
			border-top: 1px solid $colorBorder;
			padding: $base $base2;
		}
	}

	&__content {
		.header & {
			max-height: calc(90vh - #{$headerHeight});
			overflow-x: hidden;
			overflow-y: auto;
			overscroll-behavior: none;
			position: relative;
			scrollbar-color: $colorScrollBarBgHandle $colorScrollBarBgTrack;
			scrollbar-width: thin;

			&::-webkit-scrollbar {
				// height: $customScrollbarWidth;
				width: $customScrollbarWidth;
			}

			&::-webkit-scrollbar-track {
				background-color: $colorScrollBarBgTrack;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $colorScrollBarBgHandle;
			}
		}
	}

	&__group {
		margin-top: $base3;
	}

	&__groupName {
		@include font(baseSmaller);
		font-weight: bold;
	}

	&__item {
		column-gap: $base2;
		display: grid;
		grid-template-areas: 'figure	content';
		grid-template-columns: 150px auto;
		padding: $base2 0;

		&--productId,
		&--accessory {
			@include font(baseSmaller);
			display: block;
			padding: $base 0;
		}

		& + & {
			border-top: 1px solid transparentize($colorBorder, 0.5);

			&--productId,
			&--accessory {
				border-top: 0;
			}
		}

		.header & {
			border-top: 1px solid $colorBorder;
			column-gap: 0;
			grid-template-columns: 0 auto;
			padding: 0;
		}
	}

	&__itemCategory {
		@include font(searchCategory);
		margin-bottom: $baseHalf;
		order: 1;

		.header & {
			@include font(searchCategoryHeader);
			margin-bottom: 0;
		}
	}

	&__itemContent {
		display: flex;
		flex-direction: column;
		grid-area: content;
	}

	&__itemFigure {
		grid-area: figure;
	}

	&__itemDescription {
		@include font(baseSmaller);
		margin-top: $base;
		order: 3;
	}

	&__itemLink {
		border-bottom: 1px solid currentColor;
		color: currentColor;

		text-decoration: none;

		@include onHover() {
			border-bottom-color: transparent;
		}
	}

	&__itemOverallLink {
		color: currentColor;
		display: flex;
		flex-direction: column;
		padding: $baseHalf $base2 $baseHalf $base6;
		padding-bottom: $baseHalf;
		padding-top: $baseHalf;
		position: relative;
		text-decoration: none;
	}

	&__itemOverallLinkIcon {
		left: $base2;
		line-height: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: $base3;
	}

	&__itemRelatedItem {
		@include font(baseSmaller);
		display: inline-block;

		&--title {
			padding-right: $base;
		}

		&:not(&--title) + &::before {
			content: '‧';
			display: inline-block;
			padding: 0 $base;
		}
	}

	&__itemRelatedLink {
		@include font(baseSmaller);
		border-bottom: 1px solid currentColor;
		color: currentColor;
		display: inline-block;
		font-weight: normal;
		text-decoration: none;

		@include onHover() {
			border-bottom-color: transparent;
		}
	}

	&__itemRelatedLinks {
		display: flex;
		flex-wrap: wrap;
		margin-top: $base;
		order: 4;
	}

	&__itemTitle {
		@include font(searchTitle);
		flex-grow: 1;
		font-weight: bold;
		order: 2;

		.header & {
			@include font(searchTitleHeader);
		}
	}

	&__items {
		margin-top: $base;

		.header & {
			margin-top: 0;
		}
	}

	&__searchPageLink {
		@include font(searchTitleHeader);
		border-top: 1px solid $colorBorder;
		color: currentColor;
		display: block;
		padding: $base $base2 $base $base6;
		text-decoration: none;
	}
}
