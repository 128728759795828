// Style for User Generated Content
.ugc {
	a {
		@include app-link();
	}

	q,
	blockquote {
		quotes: '\00BB''\00AB';
	}

	blockquote {
		@include font(quote);
		border-left: 1px solid currentColor;
		margin: 0;
		padding-left: $base2;

		> p:first-child::before {
			content: open-quote;
		}

		> p:last-of-type::after {
			content: close-quote;
		}
	}

	cite {
		@include font(base);
		display: block;
		font-style: normal;
		text-align: right;

		&::before {
			content: '\2014';
			margin-right: 0.2em;
		}
	}

	blockquote cite {
		margin-top: $base;
	}

	q + cite {
		display: inline;
		margin-left: $base;
	}

	q {
		font-style: italic;
	}

	dl {
		margin: 0;
	}

	dd {
		margin: 0;
	}

	dt {
		@include font(base);
		display: block;
		font-weight: bold;
	}

	// this should never be used inside content,
	h1 {
		@include font(subtitle);
	}

	h2 {
		@include font(subtitle);
	}

	h3 {
		@include font(subtitle);
	}

	h4,
	h5,
	h6 {
		@include font(subtitle);
	}

	hr {
		background-color: currentColor;
		border: 0;
		height: $hrSize;
		margin: 0;
		width: 100%;
	}

	li {
		margin: 0;
	}

	ol > li {
		padding-left: 0.4em;
	}

	ol {
		margin: 0 0 0 1em;
	}

	ul {
		margin: 0 0 0 ($base * 1.5);
	}

	ul > li {
		position: relative;

		&::before {
			content: '·';
			// transform-origin: 0 50%;
			// transform: scale(1.5);
			left: -($base * 1.5);
			position: absolute;
			top: 0;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ol,
	ul,
	dl,
	blockquote {
		//max-width: $textMaxWidth;

		.block--hAlignCenter > .block__container > .block__content > &,
		.block--hAlignCenter > .block__container > .block__header & {
			margin-left: auto;
			margin-right: auto;
		}
	}

	* + a > figure {
		margin-top: 1em;
	}

	// space before the headings
	* + h1,
	* + h2,
	* + h3,
	* + h4,
	* + h5,
	* + h6 {
		margin-top: $base3;
	}

	// space before all common elements in the content
	* + table,
	* + figure,
	* + p,
	* + ol,
	* + ul,
	* + dl,
	dd + dd,
	dt + dt {
		margin-top: 1em;
	}

	// space between list items
	li + li {
		margin-top: $base;
	}

	// special style between definition list items
	dd + dt {
		margin-top: $base2;
	}

	// special margin around the blockquote
	* + blockquote,
	blockquote + *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
		margin-top: $base2;
	}

	// special margin around hr separator
	* + hr,
	hr + * {
		margin-top: $base2;
	}

	&__heading {
		&--productModelTitle {
			display: none;

			@include media($columnsBreakpoint) {
				display: block;
			}
		}
	}

	&__dl {
		&--productModelFeatures {
			dt {
				display: inline;
				font-weight: bold;
				margin: 0;
			}

			dd {
				display: inline;
				font-style: normal;
				font-weight: normal;
				margin: 0;
			}
		}
	}

	&__textHighlight {
		color: $colorFocus;
	}
}
