.about{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: $base3;
	text-align: left;
	padding: $base6 0;
	align-items: center;
	@include media('>medium'){
		flex-direction: row;
		padding: $base10+3 0;
		gap: $base10;
	}

	&__subhead{
		@include font(titleAlternative);
		margin-bottom: $base2;
		& strong{
			color: $colorFocus;
            font-style: normal;
        }
	}
	&__header{
		@include font(titleBigger);
		margin-bottom: $base2;
		& strong{
			color: $colorFocus;
            font-style: normal;
        }
	}
	&__textMain{
		color: $colorBase;
	}
	&__picture{}

	&__pictureIcon{
		width: 100%;
		@include media('>small'){
			width: auto;
		}
	}

	&__block{
		flex-basis: 60%;
	}
}
