.formField {
	background-color: $colorBrightFocus;
	border: none;
	color: $colorInverted;
	height: $formElementsHeight;
	border-radius: 0.5 * $formElementsHeight;
	padding-left: $formFieldPadding;
	padding-right: $formFieldPadding;

	&::placeholder {
		color: $colorInverted;
		opacity: 0.5;
	}
}
