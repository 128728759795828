.topic {
	border-radius: 20px;
	border: 1px solid $colorBorder;
	display: block;
	min-height: $minTileHeight;
	padding: $base2;
	position: relative;
	cursor: pointer;

	&--simple {
		min-height: $base*12;
	}

	&__checkbox {
		height: $base4;
		position: absolute;
		right: $base2;
		top: $base2;
		width: $base4;
	}

	&__copy {

	}

	&__content {
		.topic--simple & {
			margin-left: $base8;
			margin-right: $base6;
		}
	}

	&__header {
		@include font(header);
		margin-bottom: $base;
	}

	&__icon {
		width: $base6;
		margin-bottom: $base7;
		display: block;

		.topic--simple & {
			position: absolute;
			top: $base2;
			left: $base2;
		}
	}

	&__subheader {
		color: $colorSubtext;
		@include font(baseSmaller);
		margin-bottom: $baseHalf;
	}
}
