.footer {
	@include font(menuItem);
	background-color: $colorBgAlternative;
	margin-top: $base4;
	padding-top: $base7;
	padding-bottom: $base1*30;

	&.js-covered {
		// @include app-covered();
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 100%;
	}

	&__content {
		@include app-container();
		display: flex;
		flex-direction: column;
		gap: $base3;
		@include media('>small'){
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__copyrights {
		@include app-container();
		padding-top: $base2;	
	}

	&__homeLink {
		color: currentColor;
	}

	&__link {
		color: currentColor;
		text-decoration: none;
	}

	&__logo {
	}

	&__section {
		
	}

	&__sectionContent {
		.footer__sectionTitle + & {
			margin-top: $base2;
		}

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	&__sectionTitle {
		@include font(menuSectionTitle);
	}
}
