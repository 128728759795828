// the regular version is directly requested in the <head> in order to prefetch the file
@font-face {
	font-family: 'OpenSauceTwo';
	src: url('../fonts/opensaucetwo-regular-webfont.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'OpenSauceTwo';
	src: url('../fonts/opensaucetwo-bold-webfont.woff2') format('woff2');
	font-style: normal;
	font-weight: bold;
}
