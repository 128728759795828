.subscriptionthankyou {
  margin-bottom: $base10;
  margin-top: $base10;
  text-align: center;

  &__text {
	@include font(titleBigger);
	font-weight: bold;
	margin-bottom: $base5;
	color: $colorBgBase;
	& strong {
	  color: $colorFocus;
	}
	&.js-error {
		color: $colorBase;
	}
  }

  &__link {
	cursor: pointer;
	color: #5900eb;
	background-color: #fff;
	border: none;
	height: 4.3rem;
	border-radius: 2.15rem;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	display: inline-block;
  }
}

.block--subscriptionthankyou{
	.layout__column > & {
		background: url(../img/friends.gif);
		background-repeat: no-repeat;
		background-size: cover;
		height: $base*100;
		background-position: 10%;
		@include media('>small'){
			background-position: center;
		}
	}
}
