.customSelect {
	@include font(customSelect);
	display: inline-block;
	// min-width: $filterMinWidth;
	position: relative;

	&.js-toggled {
		z-index: 10;
	}

	.listFilters & {
		display: block;
		width: 100%;

		@include media('>=tileSizeSmall') {
			width: 50%;
		}

		@include media('>tileSizeMedium') {
			display: inline-block;
			width: auto;
		}

		&:nth-child(2n + 1):last-child {
			@include media('>=tileSizeSmall') {
				width: 100%;
			}

			@include media('>tileSizeMedium') {
				width: auto;
			}
		}
	}

	.listFilters &:not(.js-toggled) {
		z-index: 2;
	}

	&.js-disabled {
		pointer-events: none;
	}

	&__input {
		@include visuallyHidden();
	}

	&__inputIcon {
		@include ease(opacity, $duration2);
		color: currentColor;
		display: block;
		flex-grow: 0;
		flex-shrink: 0;
		height: auto;
		line-height: 0;
		margin-left: $base2;
		order: 2;
		width: $base * 1.5;

		// &--default {
		// 	.customSelect__input:not(:disabled) ~ .customSelect__label:hover & {
		// 		// color: $colorInputHover;
		// 	}

		// 	.customSelect__input:checked ~ .customSelect__label & {
		// 		// display: none;
		// 	}
		// }

		&--checked {
			opacity: 0;

			// .customSelect__input:not(:disabled) ~ .customSelect__label:hover & {
			// 	// color: $colorInputCheckedHover;
			// }

			.customSelect__input:checked ~ .customSelect__label & {
				opacity: 1;
			}

			.customSelect__input:checked:disabled ~ .customSelect__label & {
				opacity: $opacityInputDisabled;
			}

			.customSelect.js-implicitCheck .customSelect__input:not(:checked) ~ .customSelect__label & {
				opacity: $customSelectImplicitCheckOpacity;
			}
		}

		[data-type='svg'] {
			display: inline-block;
			height: auto;
			width: 100%;
		}
	}

	&__label {
		align-items: center;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		padding: ($base * 1.5) $base2 ($base * 1.5) ($base * 1.5);
		position: relative;
		white-space: nowrap;
		z-index: 1;

		.customSelect__input:disabled ~ & {
			pointer-events: none;
		}

		.customSelect__input:not(:disabled) ~ &:hover {
			cursor: pointer;
		}
	}

	&__labelValue {
		@include ease(opacity, $duration2);
		color: inherit;
		display: block;
		flex-grow: 1;
		flex-shrink: 0;
		order: 1;
		text-align: left;

		.customSelect__input:disabled ~ .customSelect__label & {
			opacity: $opacityInputDisabled;
		}

		.customSelect__input:not(:disabled) ~ .customSelect__label:hover & {
			// color: $colorHighlightHover;
			// border-bottom-color: $colorCustomIconHighlight;
		}
	}

	&__option {
		@include font(customSelectOption);
		display: block;
		position: relative;

		&--reset {
			bottom: 0;
			position: sticky;
			width: 100%;
			z-index: 2;
		}
	}

	&__options {
		@include ease(opacity visibility, $duration2);
		background-color: $colorBgBase;
		border: 1px solid $colorCustomSelectOptionBorder;
		box-shadow: 0 4px 17px 1px rgba(26, 26, 26, 0.18);
		display: none;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		visibility: hidden;
		width: 100%;

		.block--colorSchemeInverted & {
			background-color: $colorBgInverted;
		}

		@include media('>tileSizeMedium') {
			width: auto;
		}

		&.js-beforeOpened {
			display: block;
		}

		&.js-duringOpened {
			opacity: 1;
			visibility: inherit;
		}
	}

	&__optionsContainer {
		background-color: $colorBgBase;
		bottom: -$base * 0.8;
		height: 0;
		left: 0;
		position: absolute;
		width: 100%;

		.block--colorSchemeInverted & {
			background-color: $colorBgInverted;
		}

		@include media('>tileSizeMedium') {
			width: auto;
		}
	}

	&__optionsItems {
		max-height: $customSelectMaxOptionsHeight;
		overflow-x: hidden;
		overflow-y: auto;
		position: relative;
		scrollbar-color: $colorScrollBarBgHandle $colorScrollBarBgTrack;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			// height: $customScrollbarWidth;
			width: $customScrollbarWidth;
		}

		&::-webkit-scrollbar-track {
			background-color: $colorScrollBarBgTrack;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $colorScrollBarBgHandle;
		}
	}
}
