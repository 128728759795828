.jobs{
    &-title{
        @include font(titleBiggest);
        margin-bottom: $base8
    }
    &-branch{
        &-title{
            @include font(base);
            color: $colorFocus;
            font-weight: bold;
            margin-top: $base1*13;
            padding-bottom: $base3;
            border-bottom: 1px solid $colorFocus;
        }
    }
}

.job{
    border-bottom: 1px solid $colorFocus;

    &__wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: $base3 0 $base3 0;

        @include media('>medium'){
            flex-wrap: nowrap;
            align-items: flex-start;
        }
        &:hover{
            cursor: pointer;
        }
    }

    &-title{
        @include font(titleBigger);
        padding: 0;
        flex-basis: 88%;
        @include media('>medium'){
            // flex-basis: initial;
        }
        &::selection {
            background: transparent;
        }
    }

    &-apply{
        display: none;
        padding: $base1;
        max-height: $base4;
        white-space: nowrap;
        order: 3;
        margin-top: $base3;

        &::selection {
            background: transparent;
        }
        .job.js-active & {
            display: inline-flex;
        }

        @include media('>medium'){
            order: initial;
            margin-top: $baseHalf;
        }
    }

    &-icon{
        transition: transform 0.3s;
        @include media('>medium'){
            margin-left: $base4;
            margin-top: $base1;
        }
        & svg{
			color: transparent;
		}
        .job.js-active & {
            transform: rotate(180deg);
        }
    }

    &-content{
        display: none;
        max-width: $base1*80;
        padding: $base2 0 $base8 0;

        & ul{
            list-style-type: none;
            & li::before {
                content: "\2022";
                color: $colorFocus;
                font-weight: bold;
                display: inline-block; 
                width: $base1;
              }
        }
        
        & ol{
            list-style-image: url('../img/list.svg');
            list-style-position: inside;
        }

        .job.js-active & {
            display: block;
        }
    }
}