.slideshow {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
	position: relative;

	&__infoContainer {
		@include app-blockPadding(
			$baseClass: '.block--hSizeFullNoContentPadding.block',
			$nestedSelector: ' .slideshow &',
			$customFirstLine: false,
			$onlyDirs: (
				horizontal: true,
			)
		);
		flex-shrink: 0;
		position: relative;
		width: 100%;

		// @include media($mediumBreakpoint) {
		// }

		&--above {
			margin-bottom: $base;

			@include media($columnsBreakpoint) {
				margin-bottom: $base2;
			}
		}

		&--below.slideshow__infoContainer--navTypeNoText {
			bottom: 0;
			flex-shrink: 0;
			left: 0;
			padding-bottom: $base;
			position: absolute;
			width: 100%;
			z-index: 2;

			@include media($mediumBreakpoint) {
				bottom: auto;
				// padding-bottom: $base8;
				position: relative;
			}
		}
	}

	&__slides {
		display: grid;
		flex-grow: 1;
		min-height: 100%;
		touch-action: pan-y;
	}

	&__slidesContainer {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
		position: relative;
	}

	// &__items {
	// 	display: flex;
	// 	flex-direction: row;
	// 	flex-wrap: nowrap;
	// 	touch-action: pan-y;
	// 	width: 100%;
	// }

	// &__itemsContainer {
	// 	display: flex;
	// 	width: 100%;

	// 	@include media($columnsBreakpoint) {
	// 		// just an example, it can be smaller
	// 		width: 100%;
	// 	}

	// 	.slideshow--minHeightFixed & {
	// 		min-height: $slideMinHeight;
	// 	}

	// 	.slideshow--minHeightFixedRatio & {
	// 		@include minAspectRatio($slideRatio);
	// 	}
	// }
}
