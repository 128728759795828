.announcement{
	margin-top: $base10+3;
    @include media('>small'){
        margin-top: $base6;
    }

    &__text{
        @include font(titleBigger);
        font-weight: bold;
        & strong{
            color: $colorFocus;
        }
    }
}
