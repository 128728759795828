.topics {
	text-align: left;
	margin-top: $base10;
	overflow: hidden;

	&__copy {
		margin-top: -$base;
		margin-bottom: $base2;
		display: block;
		width: 50%;
		margin-right: $gutterSmall * 0.5;
	}

	&__header {
		@include font(infoText);
		text-align: left;
		display: inline-block;
		position: relative;
		color: $colorFocus;
		margin-bottom: $base5;

		&::after {
			position: absolute;
			width: 100vw;
			left: 100%;
			margin-left: $base2;
			top: 50%;
			content: '';
			height: 1px;
			background-color: $colorFocus;
		}
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		column-gap: $gutterSmall;
		row-gap: $gutterSmall;
		@include media('>medium'){
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&__item {
	}
}
