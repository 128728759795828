.adsBenefits{
	text-align: left;
	padding: $base6 0;
	@include media('>small'){
		padding: $base10 0;
	}
	&__header{
		@include font(titleBiggest);
		margin-bottom: $base2;
        & strong{
			color: $colorFocus;
            font-style: normal;
        }
	}

	&__items{
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		column-gap: $gutterSmall;
		row-gap: $gutterSmall;
		@include media('>small'){
			grid-template-columns: repeat(2, 1fr);
		}
		@include media('>medium'){
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

.adsBenefit{
	&__item{
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: left;
		// background: $colorFocus;
		background-image: linear-gradient(to bottom, #8a4cf1, #5900EB);
		padding: $base4;
        color: $colorBgBase;
	}
	&__header{
        @include font(titleAlternative);
		margin-bottom: $base1;
	}
	&__text{
		@include font(base);
	}
}
